import React,{useEffect} from "react";
import "./userstyle.css";
import { useNavigate } from "react-router-dom";
import {jwtDecode} from "jwt-decode"; // Ensure you import this correctly
import { Button } from "primereact/button";

export default function Userhead() {
  const navigate = useNavigate();

  const getUserDataFromToken = () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      return { clientName: null };
    }

    try {
      const decoded = jwtDecode(token);
      return {
        Name: decoded.Name || null,
        clientName: decoded.clientName || null,
      };
    } catch (e) {
      console.error("Invalid token", e);
      return { Name: null, clientName: null };
    }
  };
  
  
  const { Name, clientName } = getUserDataFromToken();
  const handleLogout = () => {
    localStorage.removeItem("authToken"); 
    navigate("/login");
    window.location.reload();
  };

  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     localStorage.removeItem("authToken"); 
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload); 
  //   };
  // }, []);

  return (
    <header>
    <div className="header-logo">
        <img src="/logo.png" alt="Logo" className="logo-image" />
      </div>
      <h1>{clientName}</h1>
      <div className="user-icon">
        <span>Welcome, {Name || "Guest"}!</span>
        <img src="/images/user-icon.png" alt="User Icon" />
        <div className="card flex justify-content-center">
          <Button
            label="Logout"
            onClick={handleLogout}
            style={{ marginLeft: "20px" }}
          />
        </div>
      </div>
    
    
    
    </header>
  );
}
