import React from "react";
import { TabMenu } from "primereact/tabmenu";
import { useNavigate } from "react-router-dom";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const Navbar = () => {
  const navigate = useNavigate();

  const items = [
    {
      label: "Dashboard",
      icon: "pi pi-home",
      command: () => navigate("/dashboard"),
    },
    {
      label: "File Upload",
      icon: "pi pi-upload",
      command: () => navigate("/file-upload"),
    },
    {
      label: "Company Name",
      icon: "pi pi-users",
      command: () => navigate("/clients"),
    },
    { label: "Users", icon: "pi pi-user", command: () => navigate("/users") },
    {
      label: "User Types",
      icon: "pi pi-id-card",
      command: () => navigate("/user-types"),
    },
    {
      label: "Reports",
      icon: "pi pi-file",
      command: () => navigate("/file-types"),
    },
  ];

  return (
    <div className="card">
      <TabMenu model={items} />
    </div>
  );
};

export default Navbar;
