import React, { useState, useEffect } from "react";
import axios from "axios";
import Usernav from "./usernav";
import Userhead from "./userhead";
import { jwtDecode } from "jwt-decode";

export default function Companydetail() {
  const [userList, setUserList] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  const getUserDataFromToken = () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      return { username: null, clientName: null };
    }

    try {
      const decoded = jwtDecode(token);
      return {
        clientName: decoded.clientName || null,
      };
    } catch (e) {
      console.error("Invalid token", e);
      return { username: null, clientName: null };
    }
  };

  const { clientName } = getUserDataFromToken();

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/clients`);
        if (clientName) {
          const filteredClients = response.data.filter(
            (client) => client.clientName === clientName
          );
          setUserList(filteredClients);
        } else {
          setUserList(response.data);
        }
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };
    fetchClients();
  }, [clientName, apiUrl]);

  return (
    <>
      <Userhead />
      <div style={{ display: "flex", height: "100vh", overflow: "hidden" }}>
        <div style={{ width: "250px" }}>
          <Usernav />
        </div>
        <div style={{ flex: 1, padding: "20px" }}>
          <h2>Company Details</h2>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            {userList.map((company, index) => (
              <div
                key={index}
                style={{
                  boxShadow: "0 1px 2px 1px rgba(0, 0, 0, 0.24)",
                  padding: "15px",
                  borderRadius: "5px",
                }}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "150px 1fr",
                    gap: "10px",
                  }}
                >
                  <div>
                    <strong>Company Name :</strong>
                  </div>
                  <div>{company.clientName}</div>
                  <div>
                    <strong>Address :</strong>
                  </div>
                  <div>{company.address}</div>
                  <div>
                    <strong>Phone Number :</strong>
                  </div>
                  <div>{company.phoneNumber}</div>
                  <div>
                    <strong>Federal ID :</strong>
                  </div>
                  <div>{company.FederalId}</div>

                  <div>
                    <strong>State ID :</strong>
                  </div>
                  <div>{company.StateId}</div>

                  <div>
                    <strong>No. of Employees :</strong>
                  </div>
                  <div>{company.NoOfEmployes}</div>

                  <div>
                    <strong>Start Date :</strong>
                  </div>
                  <div>
                    {new Date(company.StartDate).toLocaleDateString("en-GB")}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
