import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../component/style.css";
import { jwtDecode } from "jwt-decode"; // Correct import
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getUsernameFromToken = () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      return null;
    }

    try {
      const decoded = jwtDecode(token);
      return decoded.Name;
    } catch (e) {
      console.error("Invalid token", e);
      return null;
    }
  };

  const Name = getUsernameFromToken();


  const handlelogout = () => {
    localStorage.setItem("authToken", "");
    navigate("/login");
    window.location.reload();
  };


  const getPageTitle = () => {
    switch (location.pathname) {
      case "/dashboard":
        return "Admin Dashboard";
      case "/file-upload":
        return "File Upload";
      case "/clients":
        return "Company Name";
      case "/users":
        return "Users";
      case "/user-types":
        return "User Types";
      case "/file-types":
        return "Reports";
      default:
        return "Admin Dashboard";
    }
  };

  return (
    <header className="header-container">
      <div className="header-logo">
        <img src="/logo.png" alt="Logo" className="logo-image" />
      </div>
      <h1 className="page-title">{getPageTitle()}</h1>
      <div className="user-icon">
        <span>Welcome, {Name || "Guest"}!</span>
        <img src="/images/user-icon.png" alt="User Icon" />
        <div className="card flex justify-content-center">
          <Button
            label="Logout"
            onClick={handlelogout}
            style={{ marginLeft: "20px" }}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
