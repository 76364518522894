import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import {jwtDecode} from 'jwt-decode';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
        const response = await axios.post(`${apiUrl}/api/login`, { username, password });

        const { token } = response.data;

        localStorage.setItem('authToken', token);

        const decoded = jwtDecode(token);
        const userType = decoded.userType;
       

        onLogin();
        
        if (userType === "Admin") {
           navigate('/dashboard');
           window.location.reload();
        } else {
          navigate('/clientdashboard');
              window.location.reload();
        }
    } catch (error) {
        // Handle errors
        if (error.response && error.response.status === 400) {
            setErrorMessage('Invalid credentials');
        } else {
            setErrorMessage('Server error. Please try again later.');
        }
    }
    
};

  return (
    <div className="flex justify-content-center align-items-center min-h-screen">
      <Card title="Login" className="p-4 shadow-2" style={{ width: '400px', maxWidth: '100%' }}>
        <form onSubmit={handleLogin}>
          <div className="p-field p-inputgroup mb-3">
            <span className="p-inputgroup-addon">
              <i className="pi pi-user"></i>
            </span>
            <InputText
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
              required
            />
          </div>
          <div className="p-field p-inputgroup mb-3">
            <span className="p-inputgroup-addon">
              <i className="pi pi-lock"></i>
            </span>
            <InputText
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
          </div>
          {errorMessage && <div className="p-error mb-3">{errorMessage}</div>}
          <Button label="Login" type="submit" className="p-button-primary w-full" />
        </form>
      </Card>
    </div>
  );
};

export default Login;
