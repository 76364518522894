import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import CustomDialog from "../admin/component/pop-up";
import axios from "axios";
import { RadioButton } from "primereact/radiobutton";

export default function Users() {
  const [visible, setVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [userData, setUserData] = useState({
    clientName: "",
    userType: "",
    Name: "",
    username: "",
    password: "",
    fileType: [],
  });
  const [userList, setUserList] = useState([]);
  const [deleteUser, setDeleteUser] = useState(null);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [clientOptions, setClientOptions] = useState([]);
  const [usertypeOptions, setUsertypeOptions] = useState([]);
  const [filetypeOptions, setFiletypeOptions] = useState([]);
  const [clientType, setClientType] = useState("External User");
  const toast = React.useRef(null);
  const [data, setData] = useState([]);

  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/users`);
      const data = await response.json();

      const filterarr = data.map((user) => ({
        Action: user,
        username: user.username,
        clientName: user.clientName?.clientName || "Unknown Client",
        userType: user.userType?.userType || "No User Type",
        Name: user.Name || "N/A",
        fileType:
          user.fileType?.length > 0
            ? user.fileType.map((type) => type.fileType).join(", ")
            : "No Access Type",
      }));
      setData(filterarr);

      setUserList(data);
    } catch (error) {
      console.error("Error fetching clients:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch users.",
        life: 3000,
      });
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [data]);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/clients`);
        const data = await response.json();
        setClientOptions(
          data.map((client) => ({
            label: client.clientName,
            value: client._id,
          }))
        );
      } catch (error) {
        console.error("Error fetching clients:", error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to fetch clients.",
          life: 3000,
        });
      }
    };

    const fetchUsertypes = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/usertypes`);
        const data = await response.json();
        const options = data.map((type) => ({
          label: type.userType,
          value: type._id,
        }));
        setUsertypeOptions(options);
      } catch (error) {
        console.error("Error fetching user types:", error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to fetch user types.",
          life: 3000,
        });
      }
    };

    const fetchFiletypes = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/filetypes`);
        const data = await response.json();
        setFiletypeOptions(
          data.map((type) => ({ label: type.fileType, value: type._id }))
        );
      } catch (error) {
        console.error("Error fetching file types:", error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to fetch file types.",
          life: 3000,
        });
      }
    };

    fetchUsers();
    fetchClients();
    fetchUsertypes();
    fetchFiletypes();
  }, []);

  const openDialog = () => {
    setVisible(true);
  };

  const closeDialog = () => {
    setVisible(false);
    setIsEdit(false);
    setUserData({
      clientName: "",
      userType: "",
      Name: "",
      username: "",
      password: "",
      fileType: [],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let userToSubmit = {
      clientName:
        clientType === "Internal User"
          ? clientOptions.find((option) => option.label === "Internal User")
              ?.value
          : userData?.clientName?.value || userData.clientName,
      userType:
        clientType === "Internal User"
          ? usertypeOptions.find((option) => option.label === "Admin")?.value // Use value
          : userData.userType?.value || userData.userType,
      Name: userData.Name,
      username: userData.username,
      password: userData.password,
      fileType: userData.fileType,
    };

    if (
      userToSubmit.clientName &&
      userToSubmit.userType &&
      userToSubmit.Name &&
      userToSubmit.username &&
      userToSubmit.password
    ) {
      try {
        let response;

        if (editIndex !== null && userList[editIndex]?._id) {
          const userId = userList[editIndex]._id;
          response = await axios.put(
            `${apiUrl}/api/users/${userId}`,
            userToSubmit
          );
          const updatedList = [...userList];
          updatedList[editIndex] = response.data.user;
          setUserList(updatedList);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "User updated successfully.",
            life: 3000,
          });
        } else {
          response = await axios.post(`${apiUrl}/api/users`, userToSubmit);
          setUserList((prev) => [...prev, response.data.user]);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "User added successfully.",
            life: 3000,
          });
        }
        closeDialog();
        fetchUsers();
      } catch (error) {
        console.error("Error saving user:", error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to save user or Username exists.",
          life: 3000,
        });
      }
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: "All required fields must be filled.",
        life: 3000,
      });
    }
  };

  const confirmDelete = (user) => {
    setDeleteUser(user);
    setConfirmVisible(true);
  };

  const deleteConfirmed = async () => {
    try {
      await fetch(`${apiUrl}/api/users/${deleteUser._id}`, {
        method: "DELETE",
      });
      setUserList(userList.filter((user) => user._id !== deleteUser._id));
      setConfirmVisible(false);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "User deleted successfully.",
        life: 3000,
      });
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to delete user.",
        life: 3000,
      });
    }
  };

  const handleEdit = (user, index) => {
    const isInternalUser = user.clientName?.clientName === "Internal User";
    setClientType(isInternalUser ? "Internal User" : "External User");

    setIsEdit(true);
    setEditIndex(index);

    const selectedClient = clientOptions.find(
      (option) => option?.value === user?.clientName?._id
    );

    const selectedUsertype = usertypeOptions.find(
      (option) => option?.value === user?.userType?._id
    );

    const selectedfileTypes = user.fileType
      ? user.fileType.map((type) => type._id).filter(Boolean)
      : [];

    setUserData({
      clientName: selectedClient || user.clientName,
      userType: selectedUsertype?.value,
      Name: user.Name,
      username: user.username,
      password: user.password,
      fileType: selectedfileTypes,
    });

    openDialog();
  };

  const internalFields = [
    {
      name: "Name",
      label: "Name",
      icon: "pi pi-user",
      visible: true,
    },
    {
      name: "username",
      label: "Username",
      icon: "pi pi-user-edit",
      visible: true,
    },
    {
      name: "password",
      label: "Password",
      icon: "pi pi-key",
      visible: true,
    },
  ];

  const externalFields = [
    {
      name: "clientName",
      label: "Company Name",
      icon: "pi pi-users",
      type: "autocomplete",
      options: clientOptions.filter(
        (option) => option.label !== "Internal User"
      ),
      visible: true,
    },
    {
      name: "userType",
      label: "User-Type",
      icon: "pi pi-id-card",
      type: "dropdown",
      options: usertypeOptions.filter((option) => option.label !== "Admin"),
      visible: true,
    },
    {
      name: "Name",
      label: "Name",
      icon: "pi pi-user",
      visible: true,
    },
    {
      name: "username",
      label: "Username",
      icon: "pi pi-user-edit",
      visible: true,
    },
    {
      name: "password",
      label: "Password",
      icon: "pi pi-key",
      visible: true,
    },
    {
      name: "fileType",
      label: "Access Type",
      icon: "pi pi-file-pdf",
      type: "multiselect",
      options: filetypeOptions,
      visible: true,
    },
  ];

  const fields =
    clientType === "Internal User" ? internalFields : externalFields;

  return (
    <div style={{ margin: "20px" }}>
      <Toast ref={toast} />
      <div>
        <h2>{isEdit ? "Update User" : "Add User"}</h2>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "10px",
        }}
      >
        <RadioButton
          inputId="internalUser"
          name="userType"
          value="Internal User"
          checked={clientType === "Internal User"}
          onChange={(e) => setClientType(e.value)}
        />
        <label htmlFor="internalUser">Internal User</label>

        <RadioButton
          inputId="externalUser"
          name="userType"
          value="External User"
          checked={clientType === "External User"}
          onChange={(e) => setClientType(e.value)}
        />
        <label htmlFor="externalUser">External User</label>
        <Button icon="pi pi-plus" onClick={openDialog} />
      </div>

      <CustomDialog
        visible={visible}
        onHide={closeDialog}
        fields={fields}
        handleSubmit={handleSubmit}
        data={userData}
        setData={setUserData}
        clientOptions={clientOptions}
      />

      <DataTable
        value={data}
        size={10}
        tableStyle={{ minWidth: "50rem" }}
        className="p-datatable-sm"
      >
        <Column field="username" sortable header="username"></Column>
        <Column field="clientName" sortable header="Company Name"></Column>
        <Column field="userType" sortable header="userType"></Column>
        <Column field="Name" sortable header="Name"></Column>
        <Column field="fileType" sortable header="fileType"></Column>
        <Column
          field="Client Type"
          header="Client Type"
          body={(rowData) =>
            rowData?.clientName === "Internal User"
              ? "Internal User"
              : "External User"
          }
        ></Column>
        <Column
          field="Action"
          header="Actions"
          body={(rowData, { rowIndex }) => (
            <div style={{ display: "flex", gap: "20px" }}>
              <Button
                icon="pi pi-pencil"
                onClick={() => handleEdit(rowData.Action, rowIndex)}
              />
              <Button
                icon="pi pi-trash"
                onClick={() => confirmDelete(rowData.Action)}
              />
            </div>
          )}
          style={{ width: "250px" }}
        />
      </DataTable>

      <ConfirmDialog
        visible={confirmVisible}
        onHide={() => setConfirmVisible(false)}
        message="Are you sure you want to delete this user?"
        header="Delete Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={deleteConfirmed}
        reject={() => setConfirmVisible(false)}
      />
    </div>
  );
}
