// src/dashboard.js

import React from "react";

const Dashboard = () => {
  return (
    <div style={{ margin: "20px" }}>
      <h2>Welcome to the Admin Dashboard</h2>
      <p>Select an option from the menu to get started.</p>
    </div>
  );
};

export default Dashboard;
