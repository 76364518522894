import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { AutoComplete } from "primereact/autocomplete";
import CustomFileDialog from "../admin/component/customfileupload";
import axios from "axios";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import { RadioButton } from "primereact/radiobutton";

const FileUploadPage = () => {
  const [visible, setVisible] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [searchfiles, setSearchfiles] = useState([]);
  const [data, setData] = useState({
    client: "",
    userType: "",
    fileType: "",
    fileName: "",
  });
  const [clientOptions, setClientOptions] = useState([]);
  const [usertypeOptions, setUsertypeOptions] = useState([]);
  const [filetypeOptions, setFiletypeOptions] = useState([]);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [clientFilter, setClientFilter] = useState(null);
  const [filteredClients, setFilteredClients] = useState([]);
  const [documentType, setDocumentType] = useState("Reports");
  const toast = React.useRef(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchUploadedFiles = async () => {
    const endpoint =
      documentType === "Reports" ? "/api/files" : "/api/docfiles";
    try {
      const response = await axios.get(`${apiUrl}${endpoint}`);
      setUploadedFiles(response.data);
      setSearchfiles(response.data);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  useEffect(() => {
    fetchUploadedFiles();
  }, [documentType]);

  const applyFilters = () => {
    let filtered = uploadedFiles;

    if (startDate) {
      filtered = filtered.filter(
        (file) => new Date(file.uploadDate) >= startDate
      );
    }
    if (endDate) {
      filtered = filtered.filter(
        (file) => new Date(file.uploadDate) <= endDate
      );
    }
    if (clientFilter) {
      filtered = filtered.filter(
        (file) => file?.clientName?._id === clientFilter.value
      );
    }

    setSearchfiles(filtered);
  };

  const searchClients = (event) => {
    const query = event.query.toLowerCase();
    const filtered = clientOptions.filter((client) =>
      client.label.toLowerCase().includes(query)
    );
    setFilteredClients(filtered);
  };

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/clients`);
        setClientOptions(
          response.data.map((client) => ({
            label: client.clientName,
            value: client._id,
          }))
        );
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };

    const fetchUsertypes = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/usertypes`);
        setUsertypeOptions(
          response.data.map((type) => ({
            label: type.userType,
            value: type._id,
          }))
        );
      } catch (error) {
        console.error("Error fetching user types:", error);
      }
    };

    const fetchFiletypes = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/filetypes`);
        setFiletypeOptions(
          response.data.map((type) => ({
            label: type.fileType,
            value: type._id,
          }))
        );
      } catch (error) {
        console.error("Error fetching file types:", error);
      }
    };

    fetchClients();
    fetchUsertypes();
    fetchFiletypes();
    fetchUploadedFiles();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [startDate, endDate, clientFilter]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let formData = new FormData();
    formData.append("file", data.fileName);
    formData.append("clientName", data.client?.value);
    formData.append("userType", data.userType);
    formData.append("fileType", data.fileType);

    const endpoint =
      documentType === "Reports" ? "/api/upload" : "/api/docupload";

    try {
      const response = await axios.post(`${apiUrl}${endpoint}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      fetchUploadedFiles();
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "File uploaded successfully",
        life: 3000,
      });
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "File upload failed",
        life: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (fileName) => {
    const endpoint =
      documentType === "Reports" ? "/api/deletefile" : "/api/docdeletefile";
    try {
      await axios.delete(`${apiUrl}${endpoint}/${fileName}`);
      fetchUploadedFiles();
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "File deleted successfully.",
        life: 3000,
      });
    } catch (error) {
      console.error("Error deleting file:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to delete File.",
        life: 3000,
      });
    }
  };

  // Confirm delete action
  const confirmDelete = () => {
    if (deleteIndex !== null) {
      const fileNameToDelete = uploadedFiles[deleteIndex].fileName;
      handleDelete(fileNameToDelete);
      setConfirmVisible(false);
    }
  };

  const closeDialog = () => {
    setVisible(false);
    setData({ client: "", userType: "", fileType: "", fileName: null });
  };

  const groupFilesByDate = (files) => {
    return files.reduce((acc, file) => {
      let uploadDate = new Date(file.uploadDate).toLocaleDateString("en-GB");
      if (!acc[uploadDate]) {
        acc[uploadDate] = { date: uploadDate, files: [] };
      }
      acc[uploadDate].files.push(file);
      return acc;
    }, {});
  };

  const groupedFiles = groupFilesByDate(searchfiles);
  const filteredFiles = Object.values(groupedFiles);

  const headerTemplate = (data) => {
    return <span className="font-bold">Uploaded Date: {data.date}</span>;
  };

  const handleDownload = (fileId) => {
    const endpoint =
      documentType === "Reports" ? "/api/download" : "/api/docdownload";
    const url = `${apiUrl}${endpoint}/${fileId}`;
    window.open(url, "_blank");
  };

  const fields = [
    {
      name: "client",
      label: "Company Name",
      icon: "pi pi-users",
      type: "autocomplete",
      options: clientOptions,
    },
    {
      name: "userType",
      label: "User-Type",
      icon: "pi pi-id-card",
      type: "dropdown",
      options: usertypeOptions,
    },
  ];

  return (
    <div className="file-upload-page" style={{ margin: "20px" }}>
      <Toast ref={toast} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>File Upload</h2>
        <Button
          icon="pi pi-upload"
          onClick={() => setVisible(true)}
          label="Upload Files"
        />
      </div>
      <div style={{
        display: "flex",
        justifyContent: "flex-start",
        gap: "10px",
        marginBottom:"20px"
      }}>
        <RadioButton
          inputId="Reports"
          name="Reports"
          value="Reports"
          checked={documentType === "Reports"}
          onChange={() => setDocumentType("Reports")}
        />
        <label htmlFor="Reports">Reports</label>
        <RadioButton
          inputId="CompanyDoc"
          name="Reports"
          value="Reports"
          checked={documentType === "Company Documents"}
          onChange={() => setDocumentType("Company Documents")}
        />
        <label htmlFor="CompanyDoc">Company Documents</label>
      </div>

      <div className="filter-container">
        <div className="filter-row">
          <Calendar
            value={startDate}
            onChange={(e) => setStartDate(e.value)}
            placeholder="Start Date"
            showIcon
            className="filter-item"
          />
          <Calendar
            value={endDate}
            onChange={(e) => setEndDate(e.value)}
            placeholder="End Date"
            showIcon
            className="filter-item"
            style={{marginLeft:"10px"}}
          />
        </div>
        <div className="filter-row">
          <AutoComplete
            value={clientFilter}
            suggestions={filteredClients}
            completeMethod={searchClients}
            field="label"
            onChange={(e) => setClientFilter(e.value)}
            placeholder="Select Client"
            className="filter-item"
          />
          <Button
            label="Clear Filters"
            icon="pi pi-filter-slash"
            onClick={() => {
              setStartDate(null);
              setEndDate(null);
              setClientFilter(null);
              setSearchfiles(uploadedFiles); // Reset filtered files
            }}
            className="filter-item"
            style={{marginLeft:"20px"}}
          />
        </div>
      </div>

      <CustomFileDialog
        visible={visible}
        onHide={closeDialog}
        fields={fields}
        data={data}
        setData={setData}
        fileTypeOptions={filetypeOptions}
        fetchUploadedFiles={fetchUploadedFiles}
        handleSubmit={handleSubmit}
        loading={loading}
        clientOptions={clientOptions}
      />

      <h3>Uploaded Files</h3>
      {uploadedFiles.length === 0 ? (
        <p>No files uploaded yet.</p>
      ) : (
        <DataTable
          value={filteredFiles}
          rowGroupMode="subheader"
          groupRowsBy="date"
          expandableRowGroups
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowGroupHeaderTemplate={headerTemplate}
        >
          <Column
            field="date"
            header="Files"
            body={(data) => (
              <DataTable value={data.files} responsiveLayout="scroll">
                <Column
                  field="fileName"
                  header="File Name"
                  body={(rowData) => rowData?.fileName || "N/A"}
                  style={{ width:"40%" }}
                />
                <Column
                  field="clientName"
                  header="Client Name"
                  body={(rowData) => rowData?.clientName?.clientName || "N/A"}
                />
                <Column
                  field="userType"
                  header="User Type"
                  body={(rowData) => rowData?.userType?.userType || "N/A"}
                />
                <Column
                  field="fileType"
                  header="File Type"
                  body={(rowData) => rowData.fileType[0]?.fileType || "N/A"}
                />

                <Column
                  body={(rowData, { rowIndex }) => (
                    <div style={{ display: "flex", gap: "20px" }}>
                      <Button
                        icon="pi pi-download"
                        onClick={() => handleDownload(rowData._id)}
                      />
                      <Button
                        icon="pi pi-trash"
                        onClick={() => {
                          setDeleteIndex(rowIndex);
                          setConfirmVisible(true);
                        }}
                      />
                    </div>
                  )}
                  header="Action"
                  style={{ width: "150px" }}
                />
              </DataTable>
            )}
          />
        </DataTable>
      )}

      <ConfirmDialog
        visible={confirmVisible}
        onHide={() => setConfirmVisible(false)}
        message="Are you sure you want to delete this file?"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={confirmDelete}
        reject={() => setConfirmVisible(false)}
      />
    </div>
  );
};

export default FileUploadPage;
