import React, { useState, useEffect, useCallback } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { PrimeIcons } from "primereact/api";
import { AutoComplete } from "primereact/autocomplete";
import { debounce } from "lodash";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function CustomDialog({
  visible,
  onHide,
  fields,
  handleSubmit,
  data,
  setData,
  clientOptions,
}) {
  const [validationErrors, setValidationErrors] = useState({});
  const [filteredOptions, setFilteredOptions] = useState([]);

  // Custom validation function
  const validateFields = () => {
    let errors = {};

    fields.forEach((field) => {
      const value = data[field.name];

      if (!value) {
        errors[field.name] = `${field.label} is required.`;
      }

      if (field.name === "NoOfEmployes" && value) {
        if (isNaN(value)) {
          errors.NoOfEmployes = "Number of Employees must be a valid number.";
        }
      }

      if (field.name === "phoneNumber" && value) {
        const phoneRegex = /^\+?[0-9\s\-]+$/;
        const cleanedNumber = value.replace(/\D/g, "");
        if (!phoneRegex.test(value)) {
          errors.phoneNumber = "Please enter a valid phone number.";
        } else if (cleanedNumber.length < 10 || cleanedNumber.length > 15) {
          errors.phoneNumber = "Phone number must be between 10 to 15 digits.";
        }
      }

      if (field.name === "email" && value) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          errors.email = "Please enter a valid email address.";
        }
      }
    });

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      handleSubmit(e);
    }
  };

  useEffect(() => {
    if (!visible) {
      setValidationErrors({});
    }
  }, [visible]);

  useEffect(() => {
    if (!visible) {
      setFilteredOptions([]);
    }
  }, [visible]);

  const searchClients = useCallback(
    debounce((event) => {
      const query = event.query.toLowerCase();
      const filtered = clientOptions.filter((client) =>
        client.label.toLowerCase().includes(query)
      );
      setFilteredOptions(filtered);
    }, 300),
    [clientOptions]
  );

  return (
    <Dialog
      header="Add Data"
      visible={visible}
      style={{ width: "50vw" }}
      onHide={onHide}
    >
      <form onSubmit={onFormSubmit}>
        {fields.map((field, index) => (
          <div className="p-field" key={index}>
            <label htmlFor={field.name}>{field.label}</label>
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <i className={field.icon}></i>
              </span>

              {field.type === "dropdown" ? (
                <Dropdown
                  id={field.name}
                  value={data[field.name]}
                  options={field.options}
                  onChange={(e) => {
                    setData({ ...data, [field.name]: e.value });
                    if (field.onChange) field.onChange(e);
                  }}
                  placeholder={`Select ${field.label}`}
                  className={validationErrors[field.name] ? "p-invalid" : ""}
                />
              ) : field.type === "multiselect" ? (
                <MultiSelect
                  id={field.name}
                  value={data[field.name]}
                  options={field.options}
                  onChange={(e) => setData({ ...data, [field.name]: e.value })}
                  placeholder={`Select ${field.label}`}
                  display="chip"
                  className={validationErrors[field.name] ? "p-invalid" : ""}
                />
              ) : field.type === "autocomplete" ? (
                <AutoComplete
                  id={field.name}
                  value={data[field.name]}
                  suggestions={
                    filteredOptions.length
                      ? filteredOptions.filter(
                          (option) => option.label !== "Internal User"
                        )
                      : [{ label: "No results found", value: null }]
                  }
                  completeMethod={searchClients}
                  onChange={(e) => {
                    setData({ ...data, [field.name]: e.value });
                    if (field.onChange) field.onChange(e);
                  }}
                  placeholder={`Select ${field.label}`}
                  field="label"
                  className={validationErrors[field.name] ? "p-invalid" : ""}
                  dropdown
                />
              ) : field.type === "phone" ? (
                <PhoneInput
                  country={"in"}
                  value={data[field.name]}
                  onChange={(value) =>
                    setData({ ...data, [field.name]: value })
                  }
                  inputProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                  }}
                  className={validationErrors[field.name] ? "p-invalid" : ""}
                />
              ) : field.type === "Date" ? (
                <Calendar
                  id={field.name}
                  value={data[field.name] || null}
                  onChange={(e) => setData({ ...data, [field.name]: e.value })}
                  placeholder={`Select ${field.label}`}
                  dateFormat="dd-mm-yy"
                  showIcon
                  className={validationErrors[field.name] ? "p-invalid" : ""}
                />
              ) : field.type === "NumInput" ? (
                <InputText
                  id={field.name}
                  value={data[field.name] || ""}
                  onChange={(e) =>
                    setData({
                      ...data,
                      [field.name]: e.target.value.replace(/[^0-9]/g, ""),
                    })
                  }
                  className={validationErrors[field.name] ? "p-invalid" : ""}
                />
              ) : (
                <InputText
                  id={field.name}
                  value={data[field.name] || field.defaultValue || ""}
                  onChange={(e) =>
                    setData({ ...data, [field.name]: e.target.value })
                  }
                  className={validationErrors[field.name] ? "p-invalid" : ""}
                  disabled={Boolean(field.defaultValue)}
                />
              )}
            </div>
            {validationErrors[field.name] && (
              <small className="p-error">{validationErrors[field.name]}</small>
            )}
          </div>
        ))}

        <Button
          className="addfile"
          label="Submit"
          icon={PrimeIcons.CHECK}
          type="submit"
        />
      </form>
    </Dialog>
  );
}
