import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { jwtDecode } from "jwt-decode";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Userhead from "./userhead";
import Usernav from "./usernav";
import axios from "axios";

export default function Reports() {
  const { fileType } = useParams();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [validationMessage, setValidationMessage] = useState("");

  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("authToken");
  const decoded = jwtDecode(token);
  const { clientName, userType } = decoded;

  const fetchFiles = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/files`);
      const data = response.data;

      const filteredFiles = data.filter((file) => {
        const matchesClient = file?.clientName?.clientName === clientName;
        const matchesUserType = file.userType?.userType === userType;

        const matchesFileType = file.fileType.some(
          (ft) => ft.fileType.toLowerCase() === fileType.toLowerCase()
        );

        const fileUploadDate = new Date(file.uploadDate);
        const isAfterStartDate =
          !startDate || fileUploadDate >= new Date(startDate);
        const isBeforeEndDate = !endDate || fileUploadDate <= new Date(endDate);

        return (
          matchesClient &&
          matchesUserType &&
          matchesFileType &&
          isAfterStartDate &&
          isBeforeEndDate
        );
      });

      setUploadedFiles(filteredFiles);
    } catch (error) {
      console.error("Error fetching uploaded files:", error);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, [fileType, startDate, endDate]);

  const groupFilesByDate = (files) => {
    return files.reduce((acc, file) => {
      let uploadDate = new Date(file.uploadDate).toLocaleDateString("en-GB");
      if (!acc[uploadDate]) {
        acc[uploadDate] = { date: uploadDate, files: [] };
      }
      acc[uploadDate].files.push(file);
      return acc;
    }, {});
  };

  const groupedFiles = groupFilesByDate(uploadedFiles);
  const filteredFiles = Object.values(groupedFiles);

  const headerTemplate = (data) => {
    return <span className="font-bold">Uploaded Date: {data.date}</span>;
  };

  const handleDownload = (fileId) => {
    const url = `${apiUrl}/api/download/${fileId}`;
    window.open(url, "_blank");
  };

  const clearfilter = () => {
    setStartDate("");
    setEndDate("");
  };

  return (
    <>
      <Userhead />
      <div style={{ display: "flex", height: "100vh", overflow: "hidden" }}>
        <div style={{ width: "250px" }}>
          <Usernav /> 
        </div>

        <div style={{ margin: "20px", flex: 1 }}>
          <h2 style={{ fontSize: "1.5rem", fontWeight: "bold", marginBottom: "20px" ,marginLeft:"20px"}}>
            {fileType || "No Reports"}
          </h2>

          {/* Filters and Data Table */}
          <div style={{ padding: "20px", overflowY: "auto" }}>
            <div className="flex gap-3" style={{ marginBottom: "20px" }}>
              <div>
                <label htmlFor="start-date" className="font-bold block mb-2">
                  Start Date
                </label>
                <Calendar
                  id="start-date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.value)}
                  showIcon
                />
              </div>
              <div>
                <label htmlFor="end-date" className="font-bold block mb-2">
                  End Date
                </label>
                <Calendar
                  id="end-date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.value)}
                  showIcon
                />
              </div>
              <Button
                icon="pi pi-filter-slash"
                className="p-button-primary"
                style={{ height: "40px", marginTop: "1.7rem" }}
                onClick={clearfilter}
              />
            </div>

            {validationMessage && <p className="p-error">{validationMessage}</p>}
            {filteredFiles.length === 0 ? (
              <p>No files uploaded yet for {fileType}.</p>
            ) : (
              <DataTable
                value={filteredFiles}
                rowGroupMode="subheader"
                groupRowsBy="date"
                expandableRowGroups
                expandedRows={expandedRows}
                onRowToggle={(e) => setExpandedRows(e.data)}
                rowGroupHeaderTemplate={headerTemplate}
                style={{ width: "100%" }}  
              >
                <Column
                  field="date"
                  header="Files"
                  body={(data) => (
                    <DataTable value={data.files} responsiveLayout="scroll">
                      <Column
                        field="fileName"
                        header="File Name"
                        style={{ width:"40%" }}
                      />
                      <Column
                        field="fileType"
                        header="File Type"
                        body={(rowData) =>
                          rowData.fileType.map((ft) => ft.fileType).join(", ")
                        }
                      />
                      <Column
                        body={(rowData) => (
                          <div style={{ display: "flex", gap: "20px" }}>
                            <Button
                              icon="pi pi-download"
                              onClick={() => handleDownload(rowData._id)}
                            />
                          </div>
                        )}
                        header="Action"
                        style={{ width: "150px" }}
                      />
                    </DataTable>
                  )}
                />
              </DataTable>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
