import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import CustomDialog from "../admin/component/pop-up"; 
import axios from "axios";
import { parsePhoneNumber } from "libphonenumber-js";

export default function Clients() {
  const [visible, setVisible] = useState(false);
  const [userData, setUserData] = useState({
    FederalId: "",
    StateId: "",
    clientName: "",
    address: "",
    email: "",
    phoneNumber: "",
    NoOfEmployes: "",
    StartDate: "",
  });
  const [editIndex, setEditIndex] = useState(null);
  const [userList, setUserList] = useState([]);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const toast = React.useRef(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/clients`);
        setUserList(response.data);
      } catch (error) {
        console.error("Error fetching clients:", error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to fetch clients.",
          life: 3000,
        });
      }
    };
    fetchClients();
  }, []);

  const openDialog = (index) => {
    if (index !== undefined) {
      const clientData = userList[index];
      setUserData({
        ...clientData,
        StartDate: clientData.StartDate ? new Date(clientData.StartDate) : null,
      });
      setEditIndex(index);
    } else {
      setUserData({
        FederalId: "",
        StateId: "",
        clientName: "",
        address: "",
        email: "",
        phoneNumber: "",
        NoOfEmployes: "",
        StartDate: "",
      });
      setEditIndex(null);
    }
    setVisible(true);
  };

  const closeDialog = () => {
    setVisible(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (
      userData.FederalId &&
      userData.StateId &&
      userData.clientName &&
      userData.address &&
      userData.email &&
      userData.phoneNumber &&
      userData.NoOfEmployes &&
      userData.StartDate
    ) {
      try {
        // Format the phone number here
        userData.phoneNumber = formatPhoneNumber(userData.phoneNumber);

        if (editIndex !== null) {
          const clientId = userList[editIndex]._id;
          const response = await axios.put(
            `${apiUrl}/api/clients/${clientId}`,
            { ...userData }
          );
          const updatedList = [...userList];
          updatedList[editIndex] = response.data.client;
          setUserList(updatedList);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Client updated successfully.",
            life: 3000,
          });
        } else {
          const response = await axios.post(`${apiUrl}/api/clients`, {
            ...userData,
          });
          setUserList([...userList, response.data.client]);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Client added successfully.",
            life: 3000,
          });
        }
        setUserData({
          FederalId: "",
          StateId: "",
          clientName: "",
          address: "",
          email: "",
          phoneNumber: "",
          NoOfEmployes: "",
          StartDate: "",
        });
        closeDialog();
      } catch (error) {
        console.error("Error saving client:", error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to save client.",
          life: 3000,
        });
      }
    }
  };

  const confirmDelete = (index) => {
    setDeleteIndex(index);
    setConfirmVisible(true);
  };

  const deleteConfirmed = async () => {
    try {
      const clientId = userList[deleteIndex]._id;
      const clientName = userList[deleteIndex].clientName;

      const fileResponse = await axios.get(`${apiUrl}/api/files`);

      const filesForClient = fileResponse.data.filter(
        (file) => file?.clientName?.clientName === clientName
      );

      if (filesForClient.length > 0) {
        toast.current.show({
          severity: "warn",
          summary: "Cannot Delete",
          detail: "Data files for this client exist, cannot delete.",
          life: 3000,
        });
        setConfirmVisible(false);
        return;
      }

      await axios.delete(`${apiUrl}/api/clients/${clientId}`);
      const updatedList = userList.filter((_, i) => i !== deleteIndex);
      setUserList(updatedList);
      setConfirmVisible(false);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Client deleted successfully.",
        life: 3000,
      });
    } catch (error) {
      console.error("Error deleting client:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to delete client.",
        life: 3000,
      });
    }
  };

  const fields = [
    { name: "clientName", label: "Company Name", icon: "pi pi-users" },
    { name: "address", label: "Address", icon: "pi pi-map-marker" },
    {name: "phoneNumber",label: "Phone Number",  icon: "pi pi-phone",type: "phone"},
    { name: "FederalId", label: "Federal Id", icon: "pi pi-id-card" },
    { name: "StateId", label: "State Id", icon: "pi pi-id-card" },
    { name: "email", label: "Email", icon: "pi pi-envelope" },
    {
      name: "NoOfEmployes",
      label: "No Of Employes",
      icon: "pi pi-list",
      type: "NumInput",
    },
    {
      name: "StartDate",
      type: "Date",
      label: "StartDate",
      icon: "pi pi-calendar",
    },
  ];

  const formatPhoneNumber = (phoneNumber) => {
    try {
      if (!phoneNumber.startsWith("+")) {
        phoneNumber = `+${phoneNumber}`;
      }

      // Parse the phone number
      const parsedNumber = parsePhoneNumber(phoneNumber);

      if (parsedNumber && parsedNumber.isValid()) {
        return parsedNumber.formatInternational();
      } else {
        return phoneNumber;
      }
    } catch (error) {
      console.error("Error parsing phone number:", error);
      return phoneNumber;
    }
  };

  return (
    <div style={{ margin: "20px" }}>
      <Toast ref={toast} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>Add Company Name</h2>
        <Button icon="pi pi-plus" onClick={() => openDialog()} />
      </div>

      <CustomDialog
        visible={visible}
        onHide={closeDialog}
        fields={fields}
        handleSubmit={handleSubmit}
        data={userData}
        setData={setUserData}
      />

      {userList.length > 0 && (
        <DataTable value={userList} rows={10} className="p-datatable-sm">
          <Column field="clientName" header="Company Name" sortable />
          <Column field="address" header="Address" sortable />
          <Column
            field="phoneNumber"
            header="Phone"
            body={(rowData) => formatPhoneNumber(rowData?.phoneNumber)}
            sortable
          />
          <Column field="FederalId" header="Federal Id" sortable />
          <Column field="StateId" header="State Id" sortable />
          <Column field="email" header="Email" sortable />
          <Column field="NoOfEmployes" header="No Of Employes" sortable />
          <Column
            field="StartDate"
            header="Start Date"
            sortable
            body={(rowData) => {
              const localDate = new Date(rowData.StartDate).toLocaleDateString(
                "en-GB"
              );
              return localDate;
            }}
          />
          <Column
            header="Actions"
            body={(rowData, { rowIndex }) => (
              <div style={{ display: "flex", gap: "20px" }}>
                <Button
                  icon="pi pi-pencil"
                  onClick={() => openDialog(rowIndex)}
                />
                <Button
                  icon="pi pi-trash"
                  onClick={() => confirmDelete(rowIndex)}
                />
              </div>
            )}
          />
        </DataTable>
      )}
      <ConfirmDialog
        visible={confirmVisible}
        onHide={() => setConfirmVisible(false)}
        message="Are you sure you want to delete this client?"
        header="Delete Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={deleteConfirmed}
        reject={() => setConfirmVisible(false)}
      />
    </div>
  );
}
