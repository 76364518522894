import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Userhead from "./userhead";
import Usernav from "./usernav";

export default function ClientDashboard() {
  return (
    <>
      <Userhead />
      <div style={{ display: "flex", height: "100vh", overflow: "hidden" }}>
        <div style={{ width: "250px" }}>
          <Usernav />
        </div>
        
        {/* Content Area */}
        <div style={{ flex: 1, padding: "20px", overflowY: "auto" }}>
          <h2>Welcome to the Client Dashboard</h2>
          <p>Select an option from the menu to get started.</p>
        </div>
      </div>
    </>
  );
}
