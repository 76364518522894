import React, { useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { useNavigate, useLocation } from "react-router-dom";
import "./Usernav.css";

const Usernav = () => {
  const navigate = useNavigate();
  const location = useLocation(); 

  const [selectedFileType, setSelectedFileType] = useState(null);
  const [fileType, setfileType] = useState([]);

  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchFileTypes = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/filetypes`);
      const data = await response.json();
      setfileType(data);
    } catch (error) {
      console.error("Error fetching file types:", error);
    }
  };

  useEffect(() => {
    fetchFileTypes();
  }, []);

  return (
    <div className="usernav-container">
      <Sidebar className="sidebar-container">
        <Menu iconShape="circle">
          <MenuItem
            icon={<i className="pi pi-home" />}
            onClick={() => navigate("/clientdashboard")}
            className={`menu-item submenu ${
              location.pathname === "/clientdashboard"
                ? "selected-menu-item"
                : ""
            }`}
          >
            Dashboard
          </MenuItem>

          <MenuItem
            icon={<i className="pi pi-download" />}
            onClick={() => navigate("/clientdashboard")}
            className={
              location.pathname === "/clientdashboard"
                ? "selected-menu-item"
                : ""
            }
          >
            Payroll Center
          </MenuItem>

          <SubMenu label="Reports" icon={<i className="pi pi-file" />}>
            {fileType.length > 0 ? (
              fileType.map((type, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    setSelectedFileType(type.fileType);
                    navigate(`/reports/${type.fileType}`);
                  }}
                  className={`menu-item submenu ${
                    selectedFileType === type.fileType
                      ? "selected-menu-item"
                      : ""
                  }`}
                >
                  {type.fileType}
                </MenuItem>
              ))
            ) : (
              <MenuItem className="submenu" disabled>
                No File Types Available
              </MenuItem>
            )}
          </SubMenu>

          <MenuItem
            icon={<i className="pi pi-user" />}
            onClick={() => navigate("/clientdashboard")}
            className={
              location.pathname === "/clientdashboard"
                ? "selected-menu-item"
                : ""
            }
          >
            People
          </MenuItem>

          <MenuItem
            icon={<i className="pi pi-folder-open" />}
            onClick={() => navigate("/clientdashboard")}
            className={
              location.pathname === "/clientdashboard"
                ? "selected-menu-item"
                : ""
            }
          >
            User Access
          </MenuItem>

          <MenuItem
            icon={<i className="pi pi-info-circle" />}
            onClick={() => navigate("/companydetail")}
            className={`menu-item submenu ${
              location.pathname === "/companydetail" ? "selected-menu-item" : ""
            }`}
          >
            Company Details
          </MenuItem>

          <MenuItem
            icon={<i className="pi pi-file-pdf" />}
            onClick={() => navigate("/companydoc")}
            className={`menu-item submenu ${
              location.pathname === "/companydoc" ? "selected-menu-item" : ""
            }`}
          >
            Company Documents
          </MenuItem>

          <MenuItem
            icon={<i className="pi pi-cog" />}
            onClick={() => navigate("/clientdashboard")}
            className={
              location.pathname === "/clientdashboard"
                ? "selected-menu-item"
                : ""
            }
          >
            Account Setting
          </MenuItem>
        </Menu>
      </Sidebar>
    </div>
  );
};

export default Usernav;
