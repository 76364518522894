import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { PrimeIcons } from "primereact/api";
import { AutoComplete } from "primereact/autocomplete";
import { debounce } from "lodash";

export default function CustomFileDialog({
  visible,
  onHide,
  fields,
  data,
  setData,
  fileTypeOptions,
  fetchUploadedFiles,
  handleSubmit,
  clientOptions,
}) {
  const [fileInputs, setFileInputs] = useState([{ type: "", file: null }]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);

  const handleFileTypeChange = (index, value) => {
    const newFileInputs = [...fileInputs];
    newFileInputs[index].type = value;
    setFileInputs(newFileInputs);
    setData((prevData) => ({
      ...prevData,
      fileType: value,
    }));
  };

  const handleFileChange = (index, file) => {
    const newFileInputs = [...fileInputs];
    newFileInputs[index].file = file;
    setFileInputs(newFileInputs);
    setData((prevData) => ({
      ...prevData,
      fileName: file || null,
    }));
  };

  const removeFileInput = (index) => {
    setFileInputs(fileInputs.filter((_, i) => i !== index));
  };

  const addFileInput = () => {
    setFileInputs([...fileInputs, { type: "", file: null }]);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setErrorMessages([]);

    const errors = [];

    if (!data.client) errors.push("Please select a client.");
    if (!data.userType) errors.push("Please select a user type.");

    fileInputs.forEach((input, index) => {
      if (!input.file) {
        errors.push(`Please select a file for upload #${index + 1}.`);
      }
      if (!input.type) {
        errors.push(`Please select a file type for upload #${index + 1}.`);
      }
    });

    if (errors.length) {
      setErrorMessages(errors);
      return;
    }

    handleSubmit(event);
    fetchUploadedFiles();

    setFileInputs([{ type: "", file: null }]);
    onHide();
  };

  const searchClients = (event) => {
    const query = event.query.toLowerCase();
    const filtered = clientOptions.filter((client) =>
      client.label.toLowerCase().includes(query)
    );
    setFilteredOptions(filtered);
  };

  return (
    <Dialog
      header="Add Data"
      visible={visible}
      style={{ width: "50vw" }}
      onHide={onHide}
    >
      <form onSubmit={handleFormSubmit}>
        {fields.map((field, index) => (
          <div className="p-field" key={index}>
            <label htmlFor={field.name}>{field.label}</label>
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <i className={field.icon}></i>
              </span>
              {field.type === "dropdown" ? (
                <Dropdown
                  id={field.name}
                  value={data[field.name]}
                  options={field.options}
                  onChange={(e) => setData({ ...data, [field.name]: e.value })}
                  placeholder={`Select ${field.label}`}
                />
              ) : (
                <AutoComplete
                  id={field.name}
                  value={data[field.name]}
                  suggestions={filteredOptions}
                  completeMethod={debounce(searchClients, 300)}
                  onChange={(e) => setData({ ...data, [field.name]: e.value })}
                  placeholder={`Select ${field.label}`}
                  field="label"
                  dropdown
                />
              )}
            </div>
          </div>
        ))}

        <div className="p-field">
          <label>File Uploads</label>
          <table className="p-table">
            <thead>
              <tr>
                <th>File Type</th>
                <th>Choose File</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {fileInputs.map((input, index) => (
                <tr key={index}>
                  <td>
                    <Dropdown
                      value={input.type}
                      options={fileTypeOptions}
                      onChange={(e) => handleFileTypeChange(index, e.value)}
                      placeholder="Select File Type"
                    />
                  </td>
                  <td>
                    <input
                      type="file"
                      onChange={(e) =>
                        handleFileChange(index, e.target.files[0])
                      }
                    />
                  </td>
                  <td>
                    {index === 0 ? (
                      <Button
                        icon="pi pi-plus"
                        onClick={addFileInput}
                        type="button"
                      />
                    ) : (
                      <Button
                        icon="pi pi-minus"
                        onClick={() => removeFileInput(index)}
                        type="button"
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {errorMessages.length > 0 && (
          <div className="p-error">
            {errorMessages.map((error, idx) => (
              <p key={idx}>{error}</p>
            ))}
          </div>
        )}

        <Button
          className="addfile"
          label="Upload"
          icon={PrimeIcons.CHECK}
          type="submit"
        />
      </form>
    </Dialog>
  );
}
