import React, { useState, useEffect } from "react";
import Userhead from "./userhead";
import Usernav from "./usernav";
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

export default function Companydoc() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);

  const fetchUploadedFiles = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/docfiles`);
      setUploadedFiles(response.data);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  useEffect(() => {
    fetchUploadedFiles();
  }, []);

  const groupFilesByDate = (files) => {
    return files.reduce((acc, file) => {
      let uploadDate = new Date(file.uploadDate).toLocaleDateString("en-GB");
      if (!acc[uploadDate]) {
        acc[uploadDate] = { date: uploadDate, files: [] };
      }
      acc[uploadDate].files.push(file);
      return acc;
    }, {});
  };

  const groupedFiles = groupFilesByDate(uploadedFiles);
  const filteredFiles = Object.values(groupedFiles);

  const headerTemplate = (data) => {
    return <span className="font-bold">Uploaded Date: {data.date}</span>;
  };

  const handleDownload = (fileId) => {
    const url = `${apiUrl}/api/docdownload/${fileId}`;
    window.open(url, "_blank");
  };

  return (
    <>
      <Userhead />
      <div style={{ display: "flex", height: "100vh", overflow: "hidden" }}>
        <div style={{ width: "250px" }}>
          <Usernav />
        </div>

        <div style={{ flex: 1, padding: "20px" }}>
          <h2> Company Documents</h2>
          <DataTable
            value={filteredFiles}
            rowGroupMode="subheader"
            groupRowsBy="date"
            expandableRowGroups
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            rowGroupHeaderTemplate={headerTemplate}
            style={{ width: "100%" }}
          >
            <Column
              field="date"
              header="Files"
              body={(data) => (
                <DataTable value={data.files} responsiveLayout="scroll">
                  <Column
                    field="fileType"
                    header="File Type"
                    body={(rowData) =>
                      rowData.fileType.map((ft) => ft.fileType).join(", ")
                    }
                  />
                  <Column
                    field="fileName"
                    header="File Name"
                    style={{ maxWidth: "100px" }}
                  />

                  <Column
                    body={(rowData) => (
                      <div style={{ display: "flex", gap: "20px" }}>
                        <Button
                          icon="pi pi-download"
                          onClick={() => handleDownload(rowData._id)}
                        />
                      </div>
                    )}
                    header="Action"
                    style={{ width: "150px" }}
                  />
                </DataTable>
              )}
            />
          </DataTable>
        </div>
      </div>
    </>
  );
}
